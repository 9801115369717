// 系统常量文件
/**
 * @name: 系统域名
 * @author: camellia
 * @date: 2020-12-28 11:02:46
 */
export const domainUrl = process.env.VUE_APP_DOMAIN_URL;
/**
 * @name: ueditor上传文件地址
 * @author: camellia
 * @date: 2021-04-22 
 */
export const editorUploadUrl = process.env.VUE_APP_EDITOR_UPLOAD_URL;
/**
 * @name: 项目成绩数据列表每页显示数据数量
 * @author: camellia
 * @date: 2021-03-15 
 */
export const projectMarkCoursePageSize = 10;
/**
 * @name: 项目虚拟数据列表每页显示数据数量
 * @author: camellia
 * @date: 2021-03-15 
 */
export const projectVistualCoursePageSize = 10;
/**
 * @name: 项目报告数据列表每页显示数据数量
 * @author: camellia
 * @date: 2021-03-15 
 */
export const projectReportCoursePageSize = 10;
/**
 * @name: 课程分数列表每页显示数据数量
 * @author: camellia
 * @date: 2021-03-19 
 */
export const courseSorcePageSize = 10;
/**
 * @name: 课程分数列表每页显示数据数量
 * @author: camellia
 * @date: 2021-03-19 
 */
export const centerNewsPageSize = 10;
/**
 * @name: 群组学生列表每页显示数据数据量
 * @author: camellia
 * @date: 2021-03-15 
 */
export const studentGroupPageSize = 10;
/**
 * @name: 群组学生成绩每页显示数据数据量
 * @author: camellia
 * @date: 2021-03-15 
 */
export const studentScorePageSize = 10;
/**
 * @name: 群组学生成绩每页显示数据数据量
 * @author: camellia
 * @date: 2021-03-15 
 */
export const studentGamePageSize = 10;
/**
 * @name: 群组学生成绩每页显示数据数据量
 * @author: camellia
 * @date: 2021-03-15 
 */
export const studentCertPageSize = 10;
/**
 * @name: 上传文件地址
 * @author: camellia
 * @date: 2021-11-19 
 */
export const uploadFilePath = '/backend/public/index.php/project/uploadImagePic';
/**
 * @name: 上传学校logo图片
 * @author: camellia
 * @date: 2021-11-19 
 */
export const uploadSchoolLogoFilePath = '/backend/public/index.php/adminSchool/uploadImagePic';
/**
 * @name:请求加密字符
 * @author: camellia
 * @date: 2021-11-19 
 */
export const request_sign = 'bW9vbHNQQw==';
/**
 * @name: 上传开放课程封面图片
 * @author: wyf
 * @date: 2023-07-21
 */
export const uploadOpenImagePicFilePath = '/backend/public/index.php/course/uploadOpenImagePic'

