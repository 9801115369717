import { request } from "@/request/http";

export default {
    getCategoryList: (data) => {
    return request("GET", `/index.php/backarticle/getCategoryList`, data);
    },

    updateArticle: (data) => {
    return request("POST", `/index.php/backarticle/updateArticle`, data);
    },

    getArticleInfo: (data) => {
    return request("GET", `/index.php/backarticle/getArticleInfo`, data);
    },

};