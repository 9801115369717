import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";
import { startLoading, endLoading, sleep } from "@/utils/function";
import API from "@/api/teacher/article/articleUpdate"
export default {
    name: "other",
    components: {
        VueUeditorWrap
    },
    inject:['reload'],
    data() {
        return {
          // ===========================  ueditor  使用代码 start   ================
          defaultMsg: "", //默认显示的文字
          myConfig: {
            // 初始容器宽度
            initialFrameWidth: 1200,
            // 初始容器高度
            initialFrameHeight: 400,
            // 你的UEditor资源存放的路径,相对于打包后的index.html
            UEDITOR_HOME_URL: "./Editor/",
            // 编辑器不自动被内容撑高
            autoHeightEnabled: false,
            // 如果需要上传功能,找后端小伙伴要服务器接口地址
            serverUrl: editorUploadUrl,
            // 关闭自动保存
            enableAutoSave: false,
            // 自定义工具栏，需要额外选项可以参考ueditor.config.js
            toolbars: [
              [
                "inserttable",//表格
                "source", //源代码
                "bold", //加粗
                "italic", //斜体
                "underline", //下划线
                "strikethrough", //删除线
                "fontborder", //字符边框
                "blockquote", //引用
                "selectall", //全选
                "horizontal", //分隔线
                "removeformat", //清除格式
                "unlink", //取消链接
                "deletecaption", //删除表格标题
                "inserttitle", //插入标题
                "cleardoc", //清空文档
                "insertcode", //代码语言
                "fontfamily", //字体
                "fontsize", //字号
                "insertimage", //多图上传
                "link", //超链接
                "emotion", //表情
                "spechars", //特殊字符
                "searchreplace", //查询替换
                "insertvideo", //视频
                "justifyleft", //居左对齐
                "justifyright", //居右对齐
                "justifycenter", //居中对齐
                "forecolor", //字体颜色
                "insertorderedlist", //有序列表
                "insertunorderedlist", //无序列表
                "imageleft", //左浮动
                "imageright", //右浮动
                "attachment", //附件
                "imagecenter", //居中
                "lineheight", //行间距
                "scrawl", //涂鸦
              ],
            ],
          },
          ue1: "ue1", // 不同编辑器必须不同的id
          ueState: false, //ue状态
          // ===========================  ueditor  使用代码 end      ================
          // 下拉列表数据
          categoryList: [
            {
              value: "选项1",
              label: "黄金糕",
            },
            {
              value: "选项2",
              label: "双皮奶",
            },
            {
              value: "选项3",
              label: "蚵仔煎",
            },
            {
              value: "选项4",
              label: "龙须面",
            },
            {
              value: "选项5",
              label: "北京烤鸭",
            },
          ],
          folderid: "",
          // 项目id
          article_id: "",
          // 文章标题
          title: "",
          // 是否公开
          radio: "0",
          // 用户id
          userid: "",
          type:''
        };
    },
    created() {
        const self = this;
        this.$nextTick(()=>{
            let articleBox = document.querySelector('.articleBox')
            self.myConfig.initialFrameWidth = articleBox.clientWidth*0.8
        })
        self.input = this.$router.currentRoute.query.search;
        self.type = this.$router.currentRoute.query.type;
        console.log(self.type);
        self.article_id = Number(this.$router.currentRoute.query.article_id);
        self.getData();
        self.getCategoryList();
    },
    methods: {
        /**
         * @name: 获取editor示例
         * @author: camellia
         * @date: 2021-01-18 10:13:50
         */
        ready(editorInstance) {
            var self = this;
            // console.log(`编辑器实例${editorInstance.key}: `, editorInstance);
            // 添加自定义参数
            editorInstance.execCommand("serverparam", function(editor) {
                return {
                    articleid: self.article_id //设置富文本编辑器请求时，携带的额外参数
                };
            });
        },
        /**
         * @name: 获取分类列表
         * @author: camellia
         * @date: 2021-01-15 11:04:54
         */
        getCategoryList() {
            const self = this;
            let data = {};
            // 请求数据
            API.getCategoryList(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.categoryList = result.categoryList;
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        this.$Loading.hide()
                    })
                    .catch(err => {
                        // 关闭loading
                        // endLoading(startLoading()); 
                        this.$Loading.hide()
                    });
            // self.axios
            //     .get("/index.php/backarticle/getCategoryList", {
            //         params: data
            //     })
            //     .then(result => {
            //         // console.log(result);
            //         self.categoryList = result.data.categoryList;
            //     })
            //     .catch(err => {});
        },
        /**
         * @name: 返回上一级
         * @author: camellia
         * @date: 2021-01-15 14:00:30
         */
        goBack() {
            // this.$router.back()
            let self = this;
            self.$router.push({
                path: "/teacher/articleList",
                query: {
                    // article_id: self.article_id,
                    // name:self.categoryName,
                    id:self.$router.currentRoute.query.id,
                    search:self.$router.currentRoute.query.search,
                    page:self.$router.currentRoute.query.page,
                    foldername:self.$router.currentRoute.query.foldername,
                    hideMenu:0,
                    articleType:2
                }
            }).then((res)=>{
                self.reload()
                // console.log(res);
            })
                            
        },
        /**
         * @name: 修改文章
         * @author: camellia
         * @date: 2021-01-15 14:34:02
         */
        execUpdateArticle() 
        {
            const self = this;
            if (!self.title) {
                // Message.warning("请填写文章标题！");
                this.$Tips({
                    // 消息提示内容
                    message:"请填写文章标题",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            } else if (!self.defaultMsg) {
                // Message.warning("请填写文章内容！");
                this.$Tips({
                    // 消息提示内容
                    message:"请填写文章内容",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            } else if (!self.folderid) {
                // Message.warning("请选择文章分类！");
                this.$Tips({
                    // 消息提示内容
                    message:"请选择文章分类",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            }
            let data = {
                params:{
                    content: self.defaultMsg,
                    radio: self.radio,
                    title: self.title,
                    folderid: self.folderid,
                    id: self.article_id
                }

            };
            // 开启loading
            // startLoading();
            this.$Loading.show()
            API.updateArticle(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            // self.$router.push({
                            //     path: "/teacher/articleList",
                            //     query: {
                            //         // article_id: self.article_id,
                            //         // name:self.categoryName,
                            //         hideMenu:0
                            //     }
                            // }).then((res)=>{
                            //     self.reload()
                            //     // console.log(res);
                            // })
                            // Message.success(result.msg);
                            this.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'success',
                                displayTime:1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        this.$Loading.hide()
                    })
                    .catch(err => {
                        // 关闭loading
                        // endLoading(startLoading()); 
                        this.$Loading.hide()
                    });
            // self.axios
            //     .post("/index.php/backarticle/updateArticle", {
            //         params: data
            //     })
            //     .then(result => {
            //         // 关闭loading
            //         endLoading(startLoading()); 
            //         Message.success(result.data.msg);
            //         self.$router.go(-1);
            //     })
            //     .catch(err => {});
        },
        /**
         * @name: 获取显示数据
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        getData() {
            const self = this;
            let data = {
                article_id: self.article_id
            };
            // 开启loading
            // startLoading();
            this.$Loading.show()
            // 请求数据
            API.getArticleInfo(data)
                    .then((result)=>{
                        if(result.code - 0 > 0)
                        {
                            self.userid = result.userid;
                            self.title = result.data.title;
                            self.folderid = result.data.folderid;
                            self.radio = result.data.state+ '';
                            self.defaultMsg = result.data.content;
                           
                            self.ueState = true;
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        this.$Loading.hide()
                    })
                    .catch(err => {
                        // 关闭loading
                        // endLoading(startLoading()); 
                        this.$Loading.hide()
                    });
            // self.axios
            //     .get("/index.php/backarticle/getArticleInfo", {
            //         params: data
            //     })
            //     .then(result => {
            //         // console.log(result);
            //         self.userid = result.data.userid;
            //         self.title = result.data.data.title;
            //         self.folderid = result.data.data.folderid;
            //         self.radio = result.data.data.state;
            //         self.defaultMsg = result.data.data.content;
            //         self.ueState = true;
            //         // 关闭loading
            //         endLoading(startLoading()); 
            //     })
            //     .catch(err => {});
        }
    }
};
