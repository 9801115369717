<template>
  <!-- 文章修改页 -->
  <div class="articleUpdate themeFont">
    <el-card>
      <!-- 标题 -->
      <div class="articleBox">
        <div class="p-padd title">资源名称：</div>
        <div class="p-padd">
          <el-input v-model="title" placeholder="请输入内容" class="input-width"></el-input>
        </div>
      </div>
      <br />
      <!-- 分类select -->
      <div class="articleBox">
        <div class="p-padd title">所属目录：</div>
        <div class="p-padd">
          <el-select v-model="folderid" placeholder="请选择" class="input-width">
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <br />
      <!-- 正文 -->
      <div class="articleBox editor-container">
        <!-- ===================  editor组件 start  ==================== -->
        <div class="p-padd title">正文：</div>
        <!-- <vue-ueditor-wrap :config="myConfig" v-model="defaultMsg" v-if="ueState"></vue-ueditor-wrap> -->
        <div class="p-padd">
          <vue-ueditor-wrap v-if="ueState" v-model="defaultMsg" :config="myConfig" @ready="ready"></vue-ueditor-wrap>
        </div>
        <!-- ===================  editor组件 end    ==================== -->
      </div>
      <br />
      <!-- 是否公开 -->
      <div class="articleBox">
        <div class="p-padd title">公开：</div>
        <!-- <el-radio v-model="radio" label="1">公开</el-radio>
        <el-radio v-model="radio" label="0">非公开</el-radio>-->
        <div class="p-padd">
          <el-switch
            v-model="radio"
            active-value="1"
            inactive-value="0"
            active-color="#0D5570"
            inactive-color="#999999"
          ></el-switch>
        </div>
      </div>
    </el-card>
    <div class="backFix" v-if="type == 1">
      <el-button class="themecancelButton" @click="goBack()">返回</el-button>
      <!-- <el-button type="primary" @click="execUpdateArticle()"
                >提&nbsp;&nbsp;交</el-button
      >-->
    </div>
    <div class="saveFix">
      <div class="saveBox">
        <div class="floatRight">
          <el-button v-if="type == 1" class="themecancelButton" @click="goBack()">返回</el-button>
          <el-button class="themeButton" @click="execUpdateArticle()">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/teacher/article/articleUpdate.scss";
</style>
<style>
.articleUpdate .el-card__body {
  height: 80vh;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.articleUpdate .el-card__body::-webkit-scrollbar {
  display: none;
}
.articleUpdate .el-input__inner {
  font: normal normal normal 0.8333vw Source Han Sans CN !important;
  height: 2.0833vw;
}
.articleUpdate .edui-default .edui-editor {
    max-height: 50vh !important;
    border: 1px solid #d4d4d4;
    background-color: white;
    position: relative;
    overflow: scroll;
    border-radius: 4px;
}
</style>
<script>
import articleUpdate from "@/assets/js/teacher/article/articleUpdate.js";
export default {
  ...articleUpdate
};
</script>